.image-preview {
    width: 100%;
    height: 130px;
    background-position: center center;
    background: url(https://prawo-jazdy-pytania.s3.eu-central-1.amazonaws.com/others/upload-photo-icon.png);
    background-color: #fff;
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    box-shadow: 0px -3px 6px 2px rgba(0, 0, 0, 0.2);
}

.img-box {
    margin-bottom: 15px;
}

.delete-photo-btn {
    position: absolute;
    top: 0px;
    right: 15px;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    background-color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
}

.img-add-btn {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #4285f4;
    color: #fff;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
    text-align: center;
    line-height: 30px;
    margin-top: 0px;
    cursor: pointer;
    font-size: 15px;
}

.ltn__category-area {
    margin-bottom: 100px !important;
}

.ltn__img-slide-item-3 {
    max-height: 400px;
    width: auto;
}

.ltn__gallery-item-img {
    max-height: 480px;
}

.main-title {
    font-size: 370%;
}

.main-sub-title {
    font-size: 200%;
}

.city-main-title {
    font-size: 400%;
}

.city-main-sub-title {
    font-size: 200%;
}

.main-button {
    font-size: 30px !important;
}

.city-main-button {
    font-size: 30px !important;
}

@media (min-width: 600px) and (max-width: 1600px) {
    .slide-item-info-inner {
        margin-top: 130px !important;
    }
}

@media only screen and (max-width: 600px) {
    .main-title {
        margin-top: 40px;
        font-size: 165%;
    }

    .main-sub-title {
        font-size: 100%;
    }

    .city-main-title {
        margin-top: 40px;
        font-size: 240%;
    }

    .city-main-sub-title {
        font-size: 135%;
    }

    .city-slide-sub-title {
        font-size: 9px;
    }

    .city-main-button {
        font-size: 17px !important;
    }

    .main-button {
        font-size: 15px !important;
    }

    .signature {
        width: 190px !important;
        z-index: 999 !important;
        position: absolute !important;
        margin-top: 280px !important;
        margin-left: 30px !important;

        .ltn__feature-item {
            font-size: 10px !important;
            height: 60px !important;
        }

        .ltn__feature-item-6 {
            padding: 8px 37px 10px !important;
        }

        .ltn__feature-item-title {
            font-size: 20px !important;
        }
    }

    .city-signature {
        margin-top: 300px !important;
        margin-left: 16px !important;
    }

    .slide-item-info-inner {
        width: 72% !important;
    }

    .ltn__slide-item {
        width: 500px !important;
    }

    .district-ltn__slide-item {
        width: 500px !important;
        padding-bottom: 45px !important;
    }

    .ltn__section-title-2 .section-title {
        font-size: 50px !important;
    }

    .ltn__brand-logo-area {
        margin-right: 14px !important;
        margin-left: 14px !important;
    }

    .call-us-main {
        overflow: hidden !important;
        position: fixed !important;
        bottom: 0 !important;
        width: 100% !important;
        padding-top: 10px !important;
        padding-bottom: 0px !important;
    }

    .call-us-main-invisible {
        visibility: hidden !important;
        opacity: 0 !important;
        transition: visibility 0s, opacity 0.5s linear !important;
    }

    .call-us-main-visible {
        visibility: visible !important;
        opacity: 1 !important;
    }
}

.signature {
    width: 340px;
    z-index: 999;
    position: absolute;
    margin-top: 430px;
    margin-left: 280px;

    .ltn__feature-item {
        height: 110px;
    }

    .ltn__feature-item-6 {
        padding: 15px 67px 30px;
    }
}

.for-box.active::before, .for-box:hover::before {
    background-color: green;
}

.against-box.active::before, .against-box:hover::before {
    background-color: red;
}

@media (min-width: 768px) {
    .collapse.collapse-sm {
        display: block !important;
        height: auto !important;
        visibility: visible !important;
    }
}

.circle-blob {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 0.8s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

.free-time-message {
    position: fixed;
    top: 20%;
    right: 0;
    z-index: 998;
    animation: fade-in-right 0.2s ease-in-out, shake-animation 3s ease infinite normal forwards;

    a {
        border-color: #ff5e15;
    }

    @keyframes shake-animation {
        0%,
        100% {
            transform: rotate(0deg);
            transform-origin: 50% 50%;
        }
        10% {
            transform: rotate(2deg);
        }
        20%,
        40%,
        60% {
            transform: rotate(-5deg);
        }
        30%,
        50%,
        70% {
            transform: rotate(3deg);
        }
        80% {
            transform: rotate(-2deg);
        }
        90% {
            transform: rotate(2deg);
        }
    }

    @keyframes fade-in-right {
        from {
            opacity: 0;
            transform: translateX(300px);
        }
        to {
            opacity: 1;
        }
    }
}

.call-to-action-inner {
    animation: shake-animation 2s ease infinite normal forwards;

    @keyframes shake-animation {
        0% {
            animation-timing-function: ease-out;
            transform: scale(1);
            transform-origin: center center;
        }

        10% {
            animation-timing-function: ease-in;
            transform: scale(0.91);
        }

        17% {
            animation-timing-function: ease-out;
            transform: scale(1.2);
        }

        33% {
            animation-timing-function: ease-in;
            transform: scale(0.87);
        }

        45% {
            animation-timing-function: ease-out;
            transform: scale(1);
        }
    }
}
